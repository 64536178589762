
<div class="h-full min-h-full flex flex-col pt-3">
  <form @submit.prevent="submit" novalidate>
    <!-- Top third: start -->
    <div class="order-1 flex-shrink-0 container mx-auto px-4">
      <!-- Page Header: start -->
      <div class="flex items-center justify-between">
        <div class="flex-1 min-w-0">
          <div class="-ml-2 -mt-2 flex flex-col flex-wrap items-baseline">
            <h2 class="ml-2 mt-2 text-2xl leading-7 font-light text-gray-900">
              Neue Social Graphic anlegen
            </h2>
            <p class="ml-2 mt-1 text-sm text-gray-500 truncate">
              Vorlage &quot;{{ template.name }}&quot;
            </p>
          </div>
        </div>
        <div>
          <!-- Floating submit button: start -->
          <button
            :disabled="busy"
            @click="store"
            class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-brandhelfer-green hover:bg-brandhelfer-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandhelfer-green"
          >
            <!-- Heroicon name: check -->

            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </button>
          <!-- Floating submit button: end -->
        </div>
      </div>
      <!-- Page Header: end -->

      <horizontal-divider :padding="2" />

      <!-- SVG / Section selection: start -->

      <div class="flex flex-row mb-4 container mx-auto px-0">
        <div class="w-9/12">
          <spinner :busy="svgBusy"></spinner>
          <div class="template shadow-md" v-html="svg"></div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <div class="w-3/12 flex flex-col ml-3">
            <div v-if="hasBackgroundImage">
              <i
                @click="activeSection = 'background-image'"
                class="fa fa-fw fa-lg fa-image mb-4 cursor-pointer hover:text-brandhelfer-green"
                :class="
                  activeSection == 'background-image'
                    ? 'text-brandhelfer-green'
                    : ''
                "
              ></i>
            </div>

            <div v-if="hasBackgroundColors">
              <i
                @click="activeSection = 'background-color'"
                class="fa fa-fw fa-lg fa-paint-brush mb-4 cursor-pointer hover:text-brandhelfer-green"
                :class="
                  activeSection == 'background-color'
                    ? 'text-brandhelfer-green'
                    : ''
                "
              ></i>
            </div>

            <div v-if="hasTexts">
              <i
                @click="activeSection = 'text'"
                class="fa fa-fw fa-lg fa-font mb-4 cursor-pointer hover:text-brandhelfer-green"
                :class="
                  activeSection == 'text' ? 'text-brandhelfer-green' : ''
                "
              ></i>
            </div>

            <div v-if="hasExtras">
              <i
                @click="activeSection = 'extras'"
                class="fa fa-fw fa-lg fa-th-large mb-4 cursor-pointer hover:text-brandhelfer-green"
                :class="
                  activeSection == 'extras' ? 'text-brandhelfer-green' : ''
                "
              ></i>
            </div>
          </div>
        </div>
      </div>
      <!-- SVG / Section selection: end -->
    </div>
    <!-- Top third: end -->

    <!-- Middle third: start -->
    <div
      class="order-2 overflow-y-scroll bh-green-400 h-screen container mx-auto px-4 pt-2"
    >
      <!-- Background Image -->
      <div
        v-show="hasBackgroundImage && activeSection == 'background-image'"
        class="flex flex-row w-full"
      >
        <background-image
          :backgroundImage="backgroundImage"
          @imageUploading="backgroundImageUploading"
          @imageUploaded="backgroundImageUploaded"
          @statusToggled="backgroundImageStatusToggled"
        >
        </background-image>
      </div>

      <div class="flex-grow">
        <!-- Background Image -->
        <div
          v-show="
            hasBackgroundImage &&
            usesBackgroundImageLibrary &&
            activeSection == 'background-image'
          "
          class="flex flex-row w-full"
        >
          <background-image-library
            :template="template"
            @imageSelected="backgroundImageSelected"
          >
          </background-image-library>
        </div>
      </div>

      <!-- Background Color -->
      <transition name="fade">
        <div
          v-show="hasBackgroundColors && activeSection == 'background-color'"
        >
          <div
            class="flex flex-row w-full mb-4"
            v-for="backgroundColor in backgroundColors"
            :key="backgroundColor.id"
          >
            <background-color
              :smallscreen="true"
              :backgroundColor="backgroundColor"
              @colorSelected="backgroundColorSelected"
              @colorStatusToggled="backgroundColorStatusToggled"
            ></background-color>
          </div>
        </div>
      </transition>

      <!-- Text -->
      <transition name="fade">
        <div v-show="hasTexts && activeSection == 'text'" class="w-full">
          <div>Texte</div>

          <div class="mt-6">
            <div
              v-for="(text, idx) in texts"
              :key="`text${idx}`"
              class="flex flex-row w-full mb-6"
            >
              <textinput
                v-if="text.type == 'textinput'"
                :text="text"
                :smallscreen="true"
                @textEntered="textEntered"
                @textColorChanged="textColorChanged"
                @textStatusToggled="textStatusToggled"
              ></textinput>

              <textbox v-if="text.type == 'textbox'" :text="text" @textEntered="textboxTextEntered"
                              @textColorChanged="textColorChanged" @textStatusToggled="textStatusToggled">
                            </textbox>

              <selection
                v-if="text.type == 'selection'"
                :select="text"
                @optionSelected="optionSelected"
                @selectColorChanged="selectColorChanged"
                @selectStatusToggled="selectStatusToggled"
              ></selection>
            </div>
          </div>
        </div>
      </transition>

      <!-- Extras -->
      <transition name="fade">
        <div v-show="hasExtras && activeSection == 'extras'" class="w-full">
          <div class="mt-6">
            <div
              v-for="(extra, idx) in extras"
              :key="`extra${idx}`"
              class="flex flex-row w-full mb-6"
            >
              <color
                v-if="extra.type == 'color'"
                :color="extra"
                @colorChanged="extraColorChanged"
                @statusToggled="extraColorStatusToggled"
              ></color>

              <display
                v-if="extra.type == 'display'"
                :display="extra"
                @statusToggled="extraDisplayStatusToggled"
                :enabled="extra.enabled ? true : false"
              ></display>

              <display-group
                v-if="extra.type == 'displayGroup'"
                :display="extra"
                @statusToggled="extraDisplayStatusToggled"
              ></display-group>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Middle third: end -->

    <!-- Bottom third: start -->
    <navbar-smallscreen active-item="new"></navbar-smallscreen>
    <!-- Bottom third: end -->
  </form>

  <pending-modal v-if="busy"></pending-modal>
</div>

<template>
  <div>
    <div class="sm:hidden flex h-64 p-8 text-center">
      <div class="m-auto">
        <p class="text-2xl font-extralight text-gray-400">
          Reload für Mobil-optimierte Version von Social Graphics
        </p>

        <Link :href="route('social-graphics.index')" class="
            mt-8
            relative
            inline-flex
            items-center
            px-4
            py-2
            border border-transparent
            text-sm
            font-medium
            rounded-md
            text-white
            bg-brandhelfer-green
            shadow-sm
            hover:bg-brandhelfer-green
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-brandhelfer-green
          ">
        <span>Social Graphic Mobil</span>
        </Link>
      </div>
    </div>

    <div class="hidden sm:block">
      <div class="min-h-full bg-gray-100">
        <navbar></navbar>
        <impersonation-alert></impersonation-alert>

        <div class="py-5 relative">
          <visual-feedback></visual-feedback>
          <main>
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <slot></slot>
            </div>
          </main>
        </div>

        <!-- <toast></toast> -->
      </div>

      <button v-if="$page.props.auth.user.can.createSocialGraphics" @click="createNewSocialGraphic" class="
          fixed
          bottom-4
          right-4
          inline-flex
          items-center
          p-2
          border border-transparent
          rounded-full
          shadow-sm
          text-white
          bg-brandhelfer-green
          hover:bg-brandhelfer-green
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-brandhelfer-green
        ">
        <!-- Heroicon name: plus -->
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
          aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
      </button>
    </div>


    <confirm-modal v-if="confirmModal.visible" :headline="confirmModal.headline" :text="confirmModal.text"
      @close="confirmModal.visible = false" @confirm="creatingNewSocialGraphicConfirmed"></confirm-modal>
  </div>
</template>

<script>
import Navbar from '@/Shared/Header/Navbar.vue';
import VisualFeedback from '@/Shared/VisualFeedback.vue';
import ImpersonationAlert from '@/Shared/ImpersonationAlert.vue';
import Toast from '@/Shared/Toast.vue';
import ConfirmModal from '@/Shared/ConfirmModal.vue';

export default {
  components: {
    Navbar,
    VisualFeedback,
    ImpersonationAlert,
    Toast,
    ConfirmModal,
  },

  // props: {
  //   status: null
  // },

  data() {
    return {
      confirmModal: {
        visible: false,
        headline: 'Änderungen verwerfen?',
        text: 'Sie arbeiten bereits an einer neuen Social Graphic. Bisherige Eingaben verwerfen?',
      },
    };
  },

  // watch: {
  //   status: {
  //     handler: function() {
  //       if(this.status == 'alert') {
  //         alert('alert');
  //       }
  //     },
  //     deep: true
  //   }
  // },

  methods: {
    createNewSocialGraphic() {
      if (route().current() == 'social-graphics.create') {
        this.confirmModal.visible = true;
        return;
      }

      this.$inertia
        .visit(
          route('social-graphics.select-template'),
          {}
        );
    },

    creatingNewSocialGraphicConfirmed() {
      this.sending = true;

      this.$inertia
        .visit(
          route('social-graphics.select-template'),
          {}
        );
    },
  },
};
</script>
